import { defineStore } from 'pinia';
import axios from 'axios';
import { useUiState } from '~/composables';
import { useContext } from '@nuxtjs/composition-api';
import { useMainNavStore } from '../stores/mainNavStore';
import { update } from 'lodash-es';

//  this.mobileNav = false

export const useLanguageStore = defineStore({
  id: 'useLanguageStoe',
  state: () => ({
    baseURL: process.env.T3_API_BASE,
    basePath: process.env.T3_API_PATH,
    pageData: [],
    meta: [],
    slug: '',
    eventList: [],
    languageData: [
      {
        title: 'Deutsch',
        navigationTitle: 'Deutsch',
        link: '/de/',
        active: 0,
        available: 1,
      },
      {
        title: 'English',
        navigationTitle: 'English',
        link: '/en/',
        active: 0,
        available: 1,
      },
    ],
    languageBase: '',
    eventData: [],
    eventTypeList: [],
    loading: true,
    error: false,
    eventLoading: false,
    isCH: false,
    storeCode: '',
    checkoutData: [],
  }),
  actions: {
    async getCheckoutData(locale) {
      let proxyBase = '/typo3-api/';
      const jsonPath = proxyBase + locale + '/magento-checkout/';

      try {
        let response = null;

        await axios
          .get(jsonPath)
          .then((res) => {
            response = res;
          })
          .catch(async (error) => {})
          .finally(() => {});

        if (response !== null) {
          this.checkoutData = response?.data?.content;
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    setPageMeta(res) {
      this.meta = res?.data?.meta || [];
    },

    setLanguageData(res, storeCode, slug) {
      const mainNavStore = useMainNavStore();
      this.pageData = res.data;
      this.slug = slug;
      this.loading = false;
      this.pageData = res?.data;
      this.eventData = res?.data?.content?.find((item: { type: string }) => item.type == 'headlessevents_eventslist');
      this.languageData = res?.data?.i18n;
      this.eventList = res?.data?.content?.find((item: { type: string }) => item.type == 'headlessevents_eventslist')?.content?.events_list?.events;
      this.eventTypeList =
        res?.data?.content?.find((item: { type: string }) => item.type == 'headlessevents_eventslist')?.content?.events_list?.eventTypes || [];

      if (!res?.data?.footerData?.footer_newsletter) {
        mainNavStore.newsLetterLeft = '';
        mainNavStore.newsLetterLeftTop = '';
        mainNavStore.newsLetterRight = '';
      }
      // @ts-ignore
      const updateNav = this.pageData.headerData.main_menu;
      // @ts-ignore
      const updateFooterData = this.pageData.footerData.footer_menu;
      mainNavStore.mainMenuItems = updateNav;
      mainNavStore.footerData = updateFooterData;
      // @ts-ignore
      // console.log('mainnav wurde geändert')
    },

    async getLanguageData(storeCode, slug, cookie = '') {
      this.loading = true;
      this.error = false;
      this.storeCode = storeCode;
      let proxyBase = '/typo3-api/';
      const jsonPath = proxyBase + storeCode + '/' + slug;
      const { toggleLoginModal, toggleRestrictedPageStatus } = useUiState();
      const mainNavStore = useMainNavStore();

      mainNavStore.updateMobileNav(true);
      mainNavStore.showModal = false;

      try {
        let response = null;

        await axios
          .get(jsonPath)
          .then((res) => {
            response = res;
            // this.loading = false;
          })
          .catch(async (error) => {
            if (error.response.status === 403) {
              toggleRestrictedPageStatus();
              await toggleLoginModal();
            } else {
              console.error('T3 Error: ', { error });
              this.error = true;
            }
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });

        if (response !== null) {
          this.setLanguageData(response, storeCode, slug);
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
