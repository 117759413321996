export default `
mutation setShippingAddressesOnCart ($input: SetShippingAddressesOnCartInput) {
setShippingAddressesOnCart(input: $input){
    cart{
      email
      shipping_addresses {
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
    }
  }
}`;
