var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productWeightInfo"},[_c('span',{domProps:{"innerHTML":_vm._s(
      _vm.priceInScaleUnit(
        _vm.product.productType,
        _vm.product.productAmount,
        _vm.product.productWeight,
        _vm.product.productScaleUnit,
        _vm.product.discountPrice ? _vm.product.discountPrice : _vm.product.price
      )
    )}}),_vm._v(" "),_c('span',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.getZzglLink,"target":"_blank"},on:{"click":function (event) { return event.stopImmediatePropagation(); }}},[_vm._v(_vm._s(_vm.tax))])]),_vm._v(" "),(_vm.product.discountPrice && _vm.product.price)?[(_vm.product.discountLabel)?_c('span',[_vm._v(_vm._s(_vm.product.discountLabel)+" "+_vm._s(_vm.$fc(_vm.product.price)))]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }