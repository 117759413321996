<template>
  <ProductCard
    :product="getProductDataFromT3"
    :related="related"
    v-if="getProductDataFromT3"
  />
</template>

<script>
import { getVATtext } from '~/composables/utils/functions';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import ProductCard from '@/components/ProductCard.vue';
import { computed } from '@nuxtjs/composition-api';
import { useMainNavStore } from '~/stores/mainNavStore';
export default {
  name: 'ProductMagentoCard',
  components: {
    ProductCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    related: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const mainNavStore = useMainNavStore();

    const productData = computed(() => props.product);

    const getProductTitle = () => {
      return props?.product?.name;
    };

    const getProductPrice = () => {
      let productPrice = productGetters.getPrice(productData.value);
      return productPrice;
    };

    const vatText = getVATtext();

    return {
      productData,
      vatText,
      getProductTitle,
      getProductPrice,
      getProductDataFromT3: computed(() => {
        return mainNavStore.getProductDataFromT3(props.product);
      }),
    };
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.headlessproducts_productslisting {
  .product-card {
    margin-bottom: 2rem;
  }
}

.product-card > a {
  text-decoration: none;
  color: $anthracite;
  @include transition($transition-base);
  .product-card-header {
    position: relative;
    .product-badge {
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: $anthracite;
    }
    .btn {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      width: 100%;
    }
    figure {
      @include transition($transition-base);

      img {
        transform: scale(0.7);
        object-fit: contain;
      }
    }
  }
  .product-card-footer {
    .info {
      p {
        font-size: 0.875rem;
        margin: 0 0 0.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .price {
      span {
        &.text-danger {
          font-size: 0.75rem;
        }
      }
    }
  }
  &:hover {
    color: $primary !important;
    .product-card-header {
      figure {
        @include transform(scale(1.05));
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .product-card > a {
    flex-wrap: wrap;

    > * {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .product-card-footer {
      margin-top: auto;
    }
  }
}
</style>
