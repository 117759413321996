export default `
query ($cartId: String!) {
  cart(
    cart_id: $cartId
  ) {
    items {
      uid
      ... on SimpleCartItem {
        custom_options: customizable_options {
            customizable_option_uid
            label
            values {
              customizable_option_value_uid
              id
              value
              label
              price {
                type
                units
                value
              }
            }
          }
        }
      ... on ConfigurableCartItem {
        customizable_options {
            customizable_option_uid
            label
            values {
              customizable_option_value_uid
              id
              value
              label
              price {
                type
                units
                value
              }
            }
          }
      }
    }
  }
}`;
