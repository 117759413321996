<template>
  <div
    class="text-content-block"
    :class="[addLayoutTextCenter, layoutElemSpace, { row: container }]"
  >
    <h2 class="h3 my-3 mt-7" v-if="headlineTop">
      {{ headlineTop }}
    </h2>
    <div v-html="bodytext" :class="addFullwidth" v-if="bodytext"></div>
    <p
      class="text-preset-details"
      v-if="specialHeadline"
      :class="layoutSpecialHeadline"
    >
      {{ specialHeadline }}
    </p>
    <h2 class="h3 mb-3" v-if="headline">
      {{ headline }}
    </h2>
    <div class="body-base-regular mb-6" v-if="textBlock">
      {{ textBlock }}
    </div>
    <a
      :href="primaryButtonLink"
      class="btn btn-primary d-flex d-lg-inline-block justify-content-center mb-2 my-lg-0"
      v-if="primaryButtonText"
    >
      {{ primaryButtonText }}
    </a>
    <a
      :href="secondaryButtonLink"
      class="btn btn-secondary d-flex d-lg-inline-block justify-content-center mb-2 my-lg-0"
      v-if="secondaryButtonText"
    >
      {{ secondaryButtonText }}
    </a>
    <slot name="textBelow"></slot>
  </div>
</template>

<script>
export default {
  name: 'TextContentBlock',
  props: {
    specialHeadline: String,
    headline: String,
    headlineTop: String,
    textBlock: String,
    primaryButtonText: String,
    primaryButtonLink: String,
    secondaryButtonText: String,
    secondaryButtonLink: String,
    layoutSpecialHeadline: String,
    bodytext: String,
    container: Boolean,

    layoutElemSpace: {
      type: String,
      default: '',
    },
    layoutTextCenter: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean | String,
      default: false,
    },
  },
  computed: {
    addFullwidth() {
      return [this.fullwidth ? 'fullwidth' : ''];
    },
    addLayoutTextCenter() {
      return [this.layoutTextCenter ? 'text-center' : ''];
    },
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';

.text-content-block {
  > :last-child {
    margin-bottom: 0 !important;
  }

  .text-preset-details {
    &.text-preset-badge {
      margin-bottom: 0.75rem;
    }
  }

  p,
  ul {
    @include media-breakpoint-up(lg) {
      max-width: 60%;
    }

    .btn {
      margin-top: 2rem;
      width: 100%;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p.text-center {
    margin-left: auto;
    margin-right: auto;
  }

  .fullwidth {
    p,
    ul {
      max-width: inherit;
    }
  }
}
[class*='col'] {
  .text-content-block {
    p {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .text-content-block {
    .btn {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
}
</style>
