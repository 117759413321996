<template>
  <li
    class="language-switcher position-relative"
    v-click-outside="closeSwitcher"
    :class="{ active: switcherActive }"
  >
    <button class="lang-nav" @click="switcherToggle">
      <span class="icon icon-chevron-down"></span>
      <span>
        {{ selectedStore }}
      </span>
    </button>

    <div
      class="language-switcher-select-box"
      :class="{ active: switcherActive }"
    >
      <p class="body-2-semibold mb-1 language-title">{{ $t('Language') }}</p>
      <ul class="language-list" :class="{ show: languageActive }">
        <template v-for="item in languages">
          <li :key="item.twoLetterIsoCode">
            <input
              :id="`language-${item.twoLetterIsoCode}`"
              name="language"
              type="radio"
              class="d-none"
              :value="item.title"
              v-model="pickedLanguage"
            />
            <label
              :for="`language-${item.twoLetterIsoCode}`"
              class="language-label"
              @click="languageToggle"
            >
              <a :href="item.link">{{ $t(item.title) }}</a>
            </label>
          </li>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import { useLanguageStore } from '../stores/languageStore';
import { computed, useContext } from '@nuxtjs/composition-api';
import { useMagentoConfiguration } from '~/composables';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';

export default {
  name: 'LanguageSwitcher',
  directives: { clickOutside },
  setup() {
    const {
      app: { i18n },
    } = useContext();
    const { selectedStore } = useMagentoConfiguration();
    const store = useLanguageStore();
    let data = computed(() =>
      store.languageData?.filter((item) => item?.available),
    );
    let pickedLanguage = computed(() =>
      store.pageData.length
        ? store.languageData?.find((item) => item?.active)?.title
        : i18n?.loadedLanguages[0],
    );
    let loading = computed(() => store?.loading);

    let currentLocale = '';
    if (i18n?.loadedLanguages[1]) {
      currentLocale = i18n?.loadedLanguages[0];
    } else {
      currentLocale = i18n?.loadedLanguages[0];
    }

    const locales = i18n.localeCodes;

    return {
      languages: data,
      selectedStore: selectedStore.value,
      pickedLanguage,
      loading,
      locales,
      currentLocale,
    };
  },
  data() {
    return {
      switcherActive: false,
      countryShopActive: false,
      languageActive: false,
      path: this.$route.path,
    };
  },
  props: {},
  methods: {
    switcherToggle() {
      this.switcherActive = !this.switcherActive;
    },
    languageToggle() {
      this.languageActive = !this.languageActive;
    },
    closeSwitcher() {
      this.switcherActive = false;
      this.languageActive = false;
    },
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';

.language-switcher {
  &.active {
    .icon-chevron-down {
      transform: rotate(180deg);
      top: -1px;
    }
  }

  .lang-nav {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $hazelgreen;
    font-weight: 600;
  }

  .icon-chevron-down {
    position: relative;
    top: 1px;
    font-size: 1.4rem;
  }

  .language-switcher-select-box {
    display: none;
    padding: $spacer-2 $spacer-3;
    background: $sand;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-md;
    position: absolute;
    transform: translate(0%, 0.6rem);
    z-index: 99;

    @include for-desktop {
      right: 0;
    }

    &.active {
      display: block;
    }

    .language-title {
      margin-bottom: 0.125rem;
    }
    .language-list {
      width: 100%;
      position: unset;
      border: none;
      padding-left: 0;

      li {
        width: 100%;
        margin: 0 !important;
        position: relative;
        display: block;
      }

      .language-label {
        width: 100%;
        display: block;
        padding: 0.2rem 0.5rem;

        a:before {
          content: ' ';
          position: absolute;
          width: 100%;
          height: 100%;
        }
        &:hover,
        &:focus {
          background-color: $jasmine-95;
        }
      }
    }
  }
}
</style>
