<template>
  <article>
    <!-- E-Learning iframe -->
    <template v-if="element.iframeType == 'e-learning'">
      <iframe
        id="child-iframe"
        v-lazy-load :data-src="`${baseURL}/${lmsURL}/`"
        width="100%"
        style="border: none; min-height: 1000px"
        loading="lazy"
      ></iframe>
      <script>
        window.addEventListener(
          'message',
          function (e) {
            var iframe = document.querySelector('#child-iframe');
            var eventName = e.data[0];
            var data = e.data[1];
            switch (eventName) {
              case 'setHeight':
                iframe.style.height = data + 'px';
                break;
            }
          },
          false,
        );
      </script>
    </template>

    <!-- Personio iframe -->
    <template v-if="element.iframeType == 'personio'">
      <iframe
        id="personio-iframe"
        v-lazy-load data-src="https://tisso.jobs.personio.de/"
        width="100%"
        style="border: none"
        loading="lazy"
      ></iframe>
      <script>
        window.addEventListener(
          'message',
          function (e) {
            var iframe = document.querySelector('#personio-iframe');
            var eventName = e.data[0];
            var data = e.data[1];
            switch (eventName) {
              case 'setHeight':
                iframe.style.height = data + 'px';
                break;
            }
          },
          false,
        );
      </script>
    </template>

    <!-- Trustpilot widget -->
    <template v-if="element.iframeType == 'trustpilot'">
      <!-- If custom code snippet is defined use that one -->
      <div v-if="element.html" ref="trustbox" v-html="element.html"></div>

      <!-- If trustpilotType is mini use the mini widget -->
      <div v-else-if="element.trustpilotType == 'mini'" ref="trustbox">
        <div
          class="trustpilot-widget"
          data-locale="de-DE"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="59696d860000ff0005a6b052"
          data-style-height="150px"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://de.trustpilot.com/review/tisso.de"
            target="_blank"
            rel="noopener"
            class="placeholder-link"
            >Trustpilot</a
          >
        </div>
      </div>

      <!-- Default fallback code if custom snippet is not provided -->
      <template v-else>
        <div
          ref="trustbox"
          class="trustpilot-widget"
          data-locale="de-DE"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="59696d860000ff0005a6b052"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="de"
          data-font-family="Roboto"
          data-text-color="#4C4c4c"
        >
          <a
            href="https://de.trustpilot.com/review/tisso.de"
            target="_blank"
            rel="noopener"
            class="placeholder-link"
            >Trustpilot</a
          >
        </div>
      </template>
    </template>
  </article>
</template>

<script>
export default {
  name: 'IframeElement',
  data() {
    return {
      baseURL: process.env.T3_LMS_BASE,
      trustpilotScriptLoaded: false,
    };
  },
  props: {
    element: Object,
  },
  computed: {
    lmsURL() {
      return this.$i18n.localeProperties.code == 'de' ? 'kurse' : 'en/courses';
    },
  },
  methods: {
    loadScript() {
      if (process.client) {
        if (window.Trustpilot) {
          const trustbox = this.$refs.trustbox?.firstElementChild;
          trustbox && window.Trustpilot.loadFromElement(trustbox);
        } else {
          let script = document.createElement('script');
          script.src =
            'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          document.body.appendChild(script);
        }
      }
    },
    lazyLoadTrustPilot() {
      if (this.element.iframeType === 'trustpilot') {
        if (!('IntersectionObserver' in window)) {
          this.loadScript();
          return;
        }
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.loadScript();
              observer.disconnect();
            }
          });
        });
        observer.observe(this.$refs.trustbox);
      }
    },
  },
  mounted() {
    this.lazyLoadTrustPilot();
  },
};
</script>
