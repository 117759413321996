<template>
  <a :href="href" v-if="isExternalLink(href)" target="_blank">
    <slot name="content"></slot>
  </a>

  <a :href="href" v-else-if="toHome" @click.prevent="goToPage(href)">
    <slot name="content"></slot>
  </a>

  <nuxt-link :to="href" @click.native="addLoadingBar($route.path !== href)" v-else>
    <slot name="content"></slot>
  </nuxt-link>
</template>

<script>
// import { useMainNavStore } from "@/stores/mainNavStore";
import { useLanguageStore } from '@/stores/languageStore';
import {
  useContext,
  useRouter,
  computed,
  watchEffect,
  useRoute,
} from '@nuxtjs/composition-api';
import { useProdSubConfigState } from '~/composables/useProdSubConfigState';

export default {
  name: 'T3Link',
  props: {
    href: {
      type: String,
      default: '/',
    },
    toHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  setup(props, context) {
    const languageStore = useLanguageStore();
    const loading = computed(() => languageStore.loading);
    // const mainNavStore = useMainNavStore();
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();

    const { closeProdSubConfigModal } = useProdSubConfigState();

    const isExternalLink = (link) => {
      if (link?.includes('http')) {
        return true;
      }
    };

    const goToPage = async (link) => {
      event.preventDefault();

      const returnLink = link.replace(/^\//, '').slice(0, -1);
      const arrayLink = returnLink.split('/');
      const removeLocale = arrayLink.shift();

      if (props.toHome) {
        router.push(app.localeRoute({ name: 'home' }));
      } else {
        router.push(
          app.localeRoute({
            name: 'typo3',
            delimiter: '/',
            params: { slug: arrayLink },
          }),
        );
      }
    };

    const addLoadingBar = (addLoadingBar = true) => {
      if (addLoadingBar) {
        languageStore.loading = true;
      }
      window.scrollTo(0, 0);
      closeProdSubConfigModal();
    };

    return {
      addLoadingBar,
      goToPage,
      loading,
      isExternalLink,
    };
  },
};
</script>
