import { defineStore } from 'pinia';
import type { Cart } from '~/modules/GraphQL/types';
import { RelatedProduct } from '~/modules/catalog/product/composables/useRelatedProducts';

interface CustomerState {
  cart: Cart;
  relatedProducts: RelatedProduct[];
}

export const useCartStore = defineStore('cart', {
  state: (): CustomerState => ({
    cart: {
      id: '',
      is_virtual: false,
      total_quantity: 0,
      shipping_addresses: [],
    },
    relatedProducts: [],
  }),
});
