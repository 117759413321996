export default `
query attachAddressToCustomerQuery($email: String!, $storeCode: String!, $address: String!) {
  attachAddressToCustomer(
    email: $email,
    storecode: $storeCode
    CustomerAddressInput: $address
  ) {
    email
    storecode
    response
  }
}`;
