<template>
  <div :class="fullSize" v-if="imgSrc">
    <figure class="ratio" :class="elemClass">
      <img class="img-fluid" v-lazy-load :data-src="imgSrc" :alt="imgAlt" :data-not-lazy="notLazy ? true : null"/>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'RatioImage',
  props: {
    elemClass: {
      type: String,
      default: 'ratio-4x3',
    },
    imgSrc: {
      type: String,
      default: '/img/tisso-logo-desktop.svg',
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
    imgAlt: {
      type: String,
      default: '',
    },
    fullsize: {
      type: Boolean,
      default: false,
    },
    column: {
      type: String,
      default: 'img-col-1',
    },
  },
  computed: {
    fullSize() {
      return [this.fullsize ? 'elem-fullsize' : ''];
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';
.elem-fullsize {
  width: calc(100% + (var(--container-padding) * 2));
  margin-left: calc(-1 * var(--container-padding));
}
.img-col-2 {
  .ratio {
    margin: 0;
  }
}

.hero-section .ratio {
  --bs-aspect-ratio: 30.75%;
}
.ratio {
  overflow: hidden;
  margin: var(--spacer-5) 0;
  /* Landscape */
  &.ratio-5x4 {
    --bs-aspect-ratio: 80%;
  }
  &.ratio-3x2 {
    --bs-aspect-ratio: 66.667%;
  }
  &.ratio-2x1 {
    --bs-aspect-ratio: 50%;
  }
  /* Portrait */
  &.ratio-1x1 {
    --bs-aspect-ratio: 100%;
  }
  &.ratio-4x5 {
    --bs-aspect-ratio: 125%;
  }
  &.ratio-3x4 {
    --bs-aspect-ratio: 133.334%;
  }
  &.ratio-2x3 {
    --bs-aspect-ratio: 150%;
  }
  &.ratio-9x16 {
    --bs-aspect-ratio: 177.777%;
  }
  &.ratio-1x2 {
    --bs-aspect-ratio: 200%;
  }
  &.rounded {
    border-radius: 50%;
  }
  img {
    height: 100%;
    object-fit: cover;
  }
}

@include media-breakpoint-up(lg) {
  .elem-fullsize {
    width: 100%;
    margin-left: 0;
  }
}
</style>
