const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['ssr-cookie'] = require('../middleware/ssr-cookie.js')
middleware['ssr-cookie'] = middleware['ssr-cookie'].default || middleware['ssr-cookie']

export default middleware
