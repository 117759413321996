










































import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  computed,
  useContext,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import {
  useUiState,
  useUiNotification,
  useProdSubConfigState,
} from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import ProductSubConfigModal from '~/components/ProductSubConfigModal.vue';
import { useMainNavStore } from '../stores/mainNavStore';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    ProductSubConfigModal,
    NotificationBanner: () =>
      import(/* webpackPrefetch: true */ '~/components/NotificationBanner.vue'),
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () =>
      import(/* webpackPrefetch: true */ '~/components/CartSidebar.vue'),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'
      ),
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  mounted() {},
  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();
    const {
      app: { i18n },
    } = useContext();
    const { app } = useContext();
    const { closeProdSubConfigModal, isProdSubConfigModalOpen } =
      useProdSubConfigState();
    const { selectedStore } = useMagentoConfiguration();
    const { user, isAuthenticated, load: userLoad } = useUser();
    const { dismissAllNotification, notifications } = useUiNotification();
    const { state } = app.context.$vsf.$magento.config;
    const mainNavStore = useMainNavStore();

    watch(route, dismissAllNotification),
      () => {
        dismissAllNotification();
      };

    onBeforeMount(() => {
      mainNavStore.getNavData(selectedStore.value);
    });

    let notificationBannerData = computed(
      () => mainNavStore.notificationBanner,
    );

    const cookieConsentListener = ref((event: Event) => {
      // @ts-ignore
      if (window?.Cookiebot?.consent?.marketing) {
        app.$gtm.init(process.env.GOOGLE_TAG_MANAGER_ID);
      }
    });

    onMounted(async () => {
      if (route?.value?.query?.login == '1') {
        toggleLoginModal();
      }

      const { ref } = route?.value?.query;

      if (ref) {
        state.setRefCode(ref);
      }

      if (isAuthenticated.value && user.value == null) {
        await userLoad();
        try {
          app.$gtm.push({ user_id: user?.value?.email });
        } catch (e) {}
      } else {
        try {
          app.$gtm.push({ user_id: user?.value?.email });
        } catch (e) {}
      }

      window.addEventListener(
        'CookiebotOnAccept',
        cookieConsentListener.value as EventListener,
      );

      const queryParams = new URLSearchParams(window.location.search);
      if (
        !navigator.userAgent.match(
          /(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)|(PSTS[\d\.]+)/,
        ) &&
        queryParams.get('CookiebotScan') !== 'true'
      ) {
        // Create and append the new Cookiebot script
        console.log('Cookiebot script added');
        const cookiebotScript = document.createElement('script');
        cookiebotScript.id = 'Cookiebot';
        cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
        cookiebotScript.setAttribute(
          'data-cbid',
          '57d1c64f-7c06-4075-b98d-8e778c52e370',
        );
        cookiebotScript.type = 'text/javascript';
        cookiebotScript.defer = true;
        document.head.appendChild(cookiebotScript);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        'CookieConsent',
        cookieConsentListener.value as EventListener,
      );
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      closeProdSubConfigModal,
      isProdSubConfigModalOpen,
      route,
      notificationBannerData,
      isAuthenticated,
    };
  },
  head: {},
});
