<template>
  <section class="container-fluid full-with-2-cols" :class="layoutElemSpace">
    <div class="row">
      <div
        class="col-12 col-lg-6 p-0 d-flex justify-content-lg-end"
        :class="layoutLeftColBgColor"
      >
        <div class="container-50 ms-auto" :class="layoutLeftColSpace">
          <slot name="leftCol"></slot>
        </div>
      </div>
      <div
        class="col-12 col-lg-6 p-0 d-flex justify-content-lg-start"
        :class="layoutRightColBgColor"
      >
        <div class="container-50 me-auto" :class="layoutRightColSpace">
          <slot name="rightCol"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TextContentBlock from '~/components/TextContentBlock.vue';
export default {
  name: 'FullWithTwoCols',
  components: { TextContentBlock },
  props: {
    layoutElemSpace: {
      type: String,
      default: 'my-6 my-lg-15',
    },
    layoutLeftColSpace: {
      type: String,
      default: 'px-2 py-6 pt-lg-18 pe-lg-6 pt-lg-13 ps-lg-5',
    },
    layoutRightColSpace: {
      type: String,
      default: 'px-2 py-6 pt-lg-18 pe-lg-5 pt-lg-13 ps-lg-6',
    },
    layoutLeftColBgColor: {
      type: String,
      default: 'bg-bone',
    },
    layoutRightColBgColor: {
      type: String,
      default: 'bg-sand',
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';

@include media-breakpoint-up(lg) {
  .container-50 {
    max-width: calc(
      (var(--container-width) + (var(--container-padding) * 2)) / 2
    );
  }
}
</style>
