import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e55e28d = () => interopDefault(import('../pages/T3Page.vue' /* webpackChunkName: "" */))
const _3a9ed07c = () => interopDefault(import('../pages/AllProductCategoriesOverview.vue' /* webpackChunkName: "pages/AllProductCategoriesOverview" */))
const _5f59883e = () => interopDefault(import('../pages/BlogDetailPage.vue' /* webpackChunkName: "pages/BlogDetailPage" */))
const _28e998ae = () => interopDefault(import('../pages/CartPage.vue' /* webpackChunkName: "" */))
const _689d768e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _a9adf6d8 = () => interopDefault(import('../modules/checkout/pages/Checkout2.vue' /* webpackChunkName: "" */))
const _2166dc4c = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _e4083836 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _39ffdfdf = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _238dab86 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _b927577e = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _729037a0 = () => interopDefault(import('../pages/ContentElementsPage.vue' /* webpackChunkName: "pages/ContentElementsPage" */))
const _103818ea = () => interopDefault(import('../pages/ResetPasswordPage.vue' /* webpackChunkName: "" */))
const _506471da = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _2e55a581 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _2301ef43 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6f9f9086 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _1370ebfe = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _5c42a262 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _3eea2cc1 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _79c45f0d = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _6266d926 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _aa7518dc = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _55c7bf3e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _b4065624 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _670a8fe8 = () => interopDefault(import('../pages/PaypalOrder.vue' /* webpackChunkName: "" */))
const _1c492dfc = () => interopDefault(import('../pages/RegistrationForm.vue' /* webpackChunkName: "pages/RegistrationForm" */))
const _26eca576 = () => interopDefault(import('../pages/SearchPage.vue' /* webpackChunkName: "" */))
const _2512c81c = () => interopDefault(import('../pages/StripeOrder.vue' /* webpackChunkName: "" */))
const _4a76e648 = () => interopDefault(import('../pages/T3PageContent.vue' /* webpackChunkName: "pages/T3PageContent" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de/",
    component: _7e55e28d,
    name: "home___de"
  }, {
    path: "/en/",
    component: _7e55e28d,
    name: "home___en"
  }, {
    path: "/de/AllProductCategoriesOverview/",
    component: _3a9ed07c,
    pathToRegexpOptions: {"strict":true},
    name: "AllProductCategoriesOverview___de"
  }, {
    path: "/de/BlogDetailPage/",
    component: _5f59883e,
    pathToRegexpOptions: {"strict":true},
    name: "BlogDetailPage___de"
  }, {
    path: "/de/cart/",
    component: _28e998ae,
    name: "cart___de"
  }, {
    path: "/de/CartPage/",
    component: _28e998ae,
    pathToRegexpOptions: {"strict":true},
    name: "CartPage___de"
  }, {
    path: "/de/checkout/",
    component: _689d768e,
    name: "checkout___de"
  }, {
    path: "/de/checkout2/",
    component: _a9adf6d8,
    name: "checkout2___de",
    children: [{
      path: "billing/",
      component: _2166dc4c,
      name: "billing___de"
    }, {
      path: "payment/",
      component: _e4083836,
      name: "payment___de"
    }, {
      path: "shipping/",
      component: _39ffdfdf,
      name: "shipping___de"
    }, {
      path: "thank-you/",
      component: _238dab86,
      name: "thank-you___de"
    }, {
      path: "user-account/",
      component: _b927577e,
      name: "user-account___de"
    }]
  }, {
    path: "/de/ContentElementsPage/",
    component: _729037a0,
    pathToRegexpOptions: {"strict":true},
    name: "ContentElementsPage___de"
  }, {
    path: "/de/customer-reset-password/",
    component: _103818ea,
    name: "reset-password___de"
  }, {
    path: "/de/customer/",
    component: _506471da,
    meta: {"titleLabel":"My Account"},
    name: "customer___de",
    children: [{
      path: "addresses-details/",
      component: _2e55a581,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de"
    }, {
      path: "my-newsletter/",
      component: _2301ef43,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de"
    }, {
      path: "my-profile/",
      component: _6f9f9086,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de"
    }, {
      path: "my-reviews/",
      component: _1370ebfe,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de"
    }, {
      path: "my-wishlist/",
      component: _5c42a262,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de"
    }, {
      path: "order-history/",
      component: _3eea2cc1,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de"
    }, {
      path: "addresses-details/create/",
      component: _79c45f0d,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de"
    }, {
      path: "addresses-details/edit/:addressId/",
      component: _6266d926,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de"
    }, {
      path: "order-history/:orderId/",
      component: _aa7518dc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de"
    }]
  }, {
    path: "/de/Home/",
    component: _55c7bf3e,
    pathToRegexpOptions: {"strict":true},
    name: "Home___de"
  }, {
    path: "/de/Page/",
    component: _b4065624,
    pathToRegexpOptions: {"strict":true},
    name: "Page___de"
  }, {
    path: "/de/paypal-order/",
    component: _670a8fe8,
    name: "paypal-order___de"
  }, {
    path: "/de/PaypalOrder/",
    component: _670a8fe8,
    pathToRegexpOptions: {"strict":true},
    name: "PaypalOrder___de"
  }, {
    path: "/de/RegistrationForm/",
    component: _1c492dfc,
    pathToRegexpOptions: {"strict":true},
    name: "RegistrationForm___de"
  }, {
    path: "/de/ResetPasswordPage/",
    component: _103818ea,
    pathToRegexpOptions: {"strict":true},
    name: "ResetPasswordPage___de"
  }, {
    path: "/de/s/",
    component: _26eca576,
    name: "search___de"
  }, {
    path: "/de/SearchPage/",
    component: _26eca576,
    pathToRegexpOptions: {"strict":true},
    name: "SearchPage___de"
  }, {
    path: "/de/stripe-order/",
    component: _2512c81c,
    name: "stripe-order___de"
  }, {
    path: "/de/StripeOrder/",
    component: _2512c81c,
    pathToRegexpOptions: {"strict":true},
    name: "StripeOrder___de"
  }, {
    path: "/de/T3Page/",
    component: _7e55e28d,
    pathToRegexpOptions: {"strict":true},
    name: "T3Page___de"
  }, {
    path: "/de/T3PageContent/",
    component: _4a76e648,
    pathToRegexpOptions: {"strict":true},
    name: "T3PageContent___de"
  }, {
    path: "/en/AllProductCategoriesOverview/",
    component: _3a9ed07c,
    pathToRegexpOptions: {"strict":true},
    name: "AllProductCategoriesOverview___en"
  }, {
    path: "/en/BlogDetailPage/",
    component: _5f59883e,
    pathToRegexpOptions: {"strict":true},
    name: "BlogDetailPage___en"
  }, {
    path: "/en/cart/",
    component: _28e998ae,
    name: "cart___en"
  }, {
    path: "/en/CartPage/",
    component: _28e998ae,
    pathToRegexpOptions: {"strict":true},
    name: "CartPage___en"
  }, {
    path: "/en/checkout/",
    component: _689d768e,
    name: "checkout___en"
  }, {
    path: "/en/checkout2/",
    component: _a9adf6d8,
    name: "checkout2___en",
    children: [{
      path: "billing/",
      component: _2166dc4c,
      name: "billing___en"
    }, {
      path: "payment/",
      component: _e4083836,
      name: "payment___en"
    }, {
      path: "shipping/",
      component: _39ffdfdf,
      name: "shipping___en"
    }, {
      path: "thank-you/",
      component: _238dab86,
      name: "thank-you___en"
    }, {
      path: "user-account/",
      component: _b927577e,
      name: "user-account___en"
    }]
  }, {
    path: "/en/ContentElementsPage/",
    component: _729037a0,
    pathToRegexpOptions: {"strict":true},
    name: "ContentElementsPage___en"
  }, {
    path: "/en/customer-reset-password/",
    component: _103818ea,
    name: "reset-password___en"
  }, {
    path: "/en/customer/",
    component: _506471da,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details/",
      component: _2e55a581,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter/",
      component: _2301ef43,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile/",
      component: _6f9f9086,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews/",
      component: _1370ebfe,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist/",
      component: _5c42a262,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history/",
      component: _3eea2cc1,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create/",
      component: _79c45f0d,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "addresses-details/edit/:addressId/",
      component: _6266d926,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId/",
      component: _aa7518dc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/Home/",
    component: _55c7bf3e,
    pathToRegexpOptions: {"strict":true},
    name: "Home___en"
  }, {
    path: "/en/Page/",
    component: _b4065624,
    pathToRegexpOptions: {"strict":true},
    name: "Page___en"
  }, {
    path: "/en/paypal-order/",
    component: _670a8fe8,
    name: "paypal-order___en"
  }, {
    path: "/en/PaypalOrder/",
    component: _670a8fe8,
    pathToRegexpOptions: {"strict":true},
    name: "PaypalOrder___en"
  }, {
    path: "/en/RegistrationForm/",
    component: _1c492dfc,
    pathToRegexpOptions: {"strict":true},
    name: "RegistrationForm___en"
  }, {
    path: "/en/ResetPasswordPage/",
    component: _103818ea,
    pathToRegexpOptions: {"strict":true},
    name: "ResetPasswordPage___en"
  }, {
    path: "/en/s/",
    component: _26eca576,
    name: "search___en"
  }, {
    path: "/en/SearchPage/",
    component: _26eca576,
    pathToRegexpOptions: {"strict":true},
    name: "SearchPage___en"
  }, {
    path: "/en/stripe-order/",
    component: _2512c81c,
    name: "stripe-order___en"
  }, {
    path: "/en/StripeOrder/",
    component: _2512c81c,
    pathToRegexpOptions: {"strict":true},
    name: "StripeOrder___en"
  }, {
    path: "/en/T3Page/",
    component: _7e55e28d,
    pathToRegexpOptions: {"strict":true},
    name: "T3Page___en"
  }, {
    path: "/en/T3PageContent/",
    component: _4a76e648,
    pathToRegexpOptions: {"strict":true},
    name: "T3PageContent___en"
  }, {
    path: "/de/:slug+/",
    component: _7e55e28d,
    name: "typo3___de"
  }, {
    path: "/en/:slug+/",
    component: _7e55e28d,
    name: "typo3___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
