import { computed, readonly, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import cartItemsWithCustomOptions from '~/customQueries/cartItemsWithCustomOptions';
import getProductAdditionalDataGql from '~/customQueries/product/queries/getProductAdditionalData.gql';
import useRelatedProducts from '~/modules/catalog/product/composables/useRelatedProducts';
import type { RelatedProduct } from '~/modules/catalog/product/composables/useRelatedProducts';

let isSubscribedToCartWatch = false;

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
  CART,
  CART_ITEM,
  PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const relatedProducts = computed(() => cartStore.relatedProducts as RelatedProduct[]);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { search: searchRelatedProducts } = useRelatedProducts();

  if (!isSubscribedToCartWatch) {
    watch(
      cart,
      async (newCart, oldCart) => {
        if (newCart !== oldCart && newCart?.items?.length > 0) {
          await loadRelatedProducts(newCart);
        }
      },
      { deep: true },
    );
    isSubscribedToCartWatch = true;
  }

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, realCart = false } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, realCart });
      if (loadedCart && loadedCart.items.length > 0) {
        loadedCart.items = await fetchAndUpdateCustomOptions(loadedCart.items, loadedCart.id);
        loadedCart.items = await fetchProductUpdatedImages(loadedCart.items);
      }

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery });
      // loadedCart.items = await fetchAndUpdateCustomOptions(loadedCart.items, loadedCart.id)

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({ product, quantity, productConfiguration, customOptions, customQuery }): Promise<void> => {
    Logger.debug('useCart.addItem', {
      currentCart: cart.value,
      product,
      quantity,
      productConfiguration,
      customOptions,
      customQuery,
    });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customOptions,
        customQuery,
      });
      updatedCart.items = await fetchAndUpdateCustomOptions(updatedCart.items, updatedCart.id);

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
      });
      updatedCart.items = await fetchAndUpdateCustomOptions(updatedCart.items, updatedCart.id);

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({
    product,
    quantity,
    customQuery = { updateCartItems: 'customUpdateCartItems' },
    customOptions = null,
  }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
      customOptions,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
          customOptions,
        });
        updatedCart.items = await fetchAndUpdateCustomOptions(updatedCart.items, updatedCart.id);

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      updatedCart.items = await fetchAndUpdateCustomOptions(updatedCart.items, updatedCart.id);
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant?.uid;
    }
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null ? true : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  const fetchProductUpdatedImages = async (items) => {
    let skuArr = [];
    items.forEach((el) => {
      skuArr.push(el.product.sku);
    });

    const customData = await context.$magento.api.customQuery({
      query: getProductAdditionalDataGql,
      queryVariables: { filter: { sku: { in: skuArr } } },
    });

    if (customData?.errors?.length > 0) {
      return items;
    }

    // @ts-ignore
    const itemsWithOptions = customData?.data?.products?.items;
    // let base = "https://typo3-assets-stage.s3.eu-central-1.amazonaws.com/tisso_media/media/catalog/product";
    let base = 'https://tisso-assets.s3.eu-central-1.amazonaws.com/tisso_media/media/catalog/product';

    return items.map((item, index) => {
      for (let i in itemsWithOptions) {
        if (itemsWithOptions[i].sku == item.product.sku) {
          let img = itemsWithOptions[i].swatch_image;
          if (img[0] == '/') {
            item.product.swatch_image = base + img;
          } else {
            item.product.swatch_image = base + '/' + img;
          }
        }
      }
      return { ...item };
    });
  };

  const fetchAndUpdateCustomOptions = async (items, cartId) => {
    const customOptionsData = await context.$magento.api.customQuery({
      query: cartItemsWithCustomOptions,
      queryVariables: { cartId },
    });

    if (customOptionsData?.errors?.length > 0) {
      return items;
    }

    // @ts-ignore
    const itemsWithCustomOptions = customOptionsData?.data?.cart?.items;

    return items.map((item, index) => {
      let customizable_options;
      if (itemsWithCustomOptions[index].__typename == 'SimpleCartItem') customizable_options = itemsWithCustomOptions[index].custom_options;
      else if (itemsWithCustomOptions[index].__typename == 'ConfigurableCartItem')
        customizable_options = itemsWithCustomOptions[index].customizable_options;
      return { ...item, customizable_options };
    });
  };

  /**
   * Loads related products for current cart
   * @param cart
   *
   * @return void
   */
  const loadRelatedProducts = async (cart: Cart): Promise<void> => {
    const baseSearchQuery = {
      filter: {
        sku: {
          eq: cart.items[0].product.sku,
        },
      },
    };

    let relatedProducts = await searchRelatedProducts(baseSearchQuery);

    // Get SKUs of products in the cart
    const cartProductSKUs = cart.items.map((item) => item.product.sku);

    // Filter out related products that are already in the cart
    relatedProducts = relatedProducts.filter((product) => {
      return !cartProductSKUs.includes(product.sku);
    });
    cartStore.$patch((state) => {
      state.relatedProducts = relatedProducts;
    });
  };

  return {
    setCart,
    cart,
    relatedProducts,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useCart;
export * from './useCart';
