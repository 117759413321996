const fragmentPriceRangeFields = `
  fragment PriceRangeFields on PriceRange {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
      discount {
        amount_off
        percent_off
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
      discount {
        amount_off
        percent_off
      }
    }
  }
`;

export default `
query ($sku:String!) {
  products(filter: {sku: {eq: $sku}}) {
    items {
      uid
      name
      sku
      unit_type
      unit_value
      item_unit
      inhalt
      inhalt_label
      image {
        url
        label
      }
      price_range {
          ...PriceRangeFields
        }
      __typename
      ... on CustomizableProductInterface {
        options {
          option_id
          title
          required
          sort_order
          uid
          ... on CustomizableDropDownOption {
            dropDown: value {
              sku
              title
              option_type_id
              price_type
              price
              uid
            }
          }
        }
      }
    }
  }
}

${fragmentPriceRangeFields}
`;
