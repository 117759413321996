<template>
  <div class="productWeightInfo">
    <span
      v-html="
        priceInScaleUnit(
          product.productType,
          product.productAmount,
          product.productWeight,
          product.productScaleUnit,
          product.discountPrice ? product.discountPrice : product.price,
        )
      "
    />
    <span
      ><a
        :href="getZzglLink"
        @click="(event) => event.stopImmediatePropagation()"
        target="_blank"
        class="text-decoration-none"
        >{{ tax }}</a
      ></span
    >

    <template v-if="product.discountPrice && product.price">
      <span v-if="product.discountLabel"
        >{{ product.discountLabel }} {{ $fc(product.price) }}</span
      >
    </template>
  </div>
</template>

<script>
import { useLanguageStore } from '../stores/languageStore';
import { getVAT } from '~/composables/utils/functions';
import { computed } from '@nuxtjs/composition-api';
export default {
  name: 'ProductWeightInfo',

  props: {
    product: Object,
    tax: String,
  },
  computed: {
    getZzglLink() {
      return getVAT();
    },
  },
  methods: {
    priceInScaleUnit(pType, pAmount, pWeight, pScaleUnit, price) {
      if (pScaleUnit === 'g') {
        const pricePerKG = (price / pWeight) * 1000;
        return '1 kg = ' + this.$fc(pricePerKG);
      } else if (pScaleUnit === 'ml') {
        const pricePerL = (price / pWeight) * 1000;
        return '1 L = ' + this.$fc(pricePerL);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/styles/misc/vars';

.productWeightInfo {
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.75rem;
    line-height: 1.17rem;
    margin-bottom: 0.25rem;
    color: $gray-500;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
