export default `
query cartSelectPaymentMethodQuery($cartId: String!) {
  cart(
    cart_id: $cartId
  ) {
    selected_payment_method {
      code
      title
    }
  }
}`;
