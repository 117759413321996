import type { NuxtCookies, GetOptions } from 'cookie-universal-nuxt';
import type { CookieSerializeOptions } from 'cookie';
import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';
import { defaultConfig } from '~/modules/core/defaultConfig';

const moduleOptions = JSON.parse('{"cookies":{"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale","cartCookieName":"vsf-cart","customerCookieName":"vsf-customer","storeCookieName":"vsf-store","messageCookieName":"vsf-message","typo3UserCookieName":"fe_typo_user","docCheckRedirectCookieName":"ap_docchecklogin_redirect","refCodeCookieName":"ref_code"},"cookiesDefaultOpts":{"httpOnly":false,"secure":true,"sameSite":"Strict","path":"/"},"externalCheckout":{"enable":false,"cmsUrl":"https://ecommerce.tisso.de","syncUrlPath":"/vue/cart/sync","stores":{"default":false}},"defaultStore":"default","magentoBaseUrl":"https://ecommerce.tisso.de","imageProvider":"ipx","magentoApiEndpoint":"https://ecommerce.tisso.de/graphql","customApolloHttpLinkOptions":{"useGETForQueries":true},"customer":{"customer_create_account_confirm":true}}');

export default integrationPlugin((plugin) => {
  const getCookieName = (property: keyof (typeof defaultConfig)['cookies']): string =>
    moduleOptions.cookies?.[property] ?? defaultConfig.cookies[property];
  const date = new Date();
  const cookiesDefaultOpts = Object.assign(moduleOptions.cookiesDefaultOpts, {
    expires: new Date(date.setDate(date.getDate() + 30)),
  });
  const cookieOpts = {
    [defaultConfig.cookies.currencyCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.localeCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.storeCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.customerCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.cartCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.messageCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.docCheckRedirectCookieName]: { ...cookiesDefaultOpts },
    [defaultConfig.cookies.refCodeCookieName]: { ...cookiesDefaultOpts },
  };
  const getCookieOpt = (cookieName?: string): CookieSerializeOptions => cookieOpts[cookieName] ?? {};

  const cookieNames = {
    cart: getCookieName('cartCookieName'),
    customer: getCookieName('customerCookieName'),
    currency: getCookieName('currencyCookieName'),
    store: getCookieName('storeCookieName'),
    locale: getCookieName('localeCookieName'),
    country: getCookieName('countryCookieName'),
    message: getCookieName('messageCookieName'),

    fetypouser: getCookieName('typo3UserCookieName'),
    docCheckRedirect: getCookieName('docCheckRedirectCookieName'),

    refCode: getCookieName('refCodeCookieName'),
  };

  const { $cookies } = plugin.app;

  const createCookieOperationsInstance =
    <TValue = string>(cookies: NuxtCookies) =>
    (cookieName: string) => ({
      get: (opts?: GetOptions) => cookies.get(cookieName, opts),
      set: (value: TValue, opts?: CookieSerializeOptions) => cookies.set(cookieName, value, { ...getCookieOpt(cookieName), ...opts }),
      remove: (opts?: CookieSerializeOptions) => cookies.remove(cookieName, opts),
    });

  const createCookieOperations = createCookieOperationsInstance($cookies);

  // TODO Refactor to separate containers (state.cart.get() .set() .remove()) - this requires a breaking change in api-client types

  const { get: getCartId, set: setCartId, remove: removeCartId } = createCookieOperations(cookieNames.cart);

  const { get: getCustomerToken, set: setCustomerToken, remove: removeCustomerToken } = createCookieOperations(cookieNames.customer);

  const { get: getStore, set: setStore, remove: removeStore } = createCookieOperations(cookieNames.store);

  const { get: getCurrency, set: setCurrency, remove: removeCurrency } = createCookieOperations(cookieNames.currency);

  const { get: getLocale, set: setLocale, remove: removeLocale } = createCookieOperations(cookieNames.locale);

  const { get: getCountry, set: setCountry, remove: removeCountry } = createCookieOperations(cookieNames.country);

  const { get: getMessage, set: setMessage, remove: removeMessage } = createCookieOperations(cookieNames.message);

  const { get: getFeTypoUserId, set: setFeTypoUserId, remove: removeFeTypoUserId } = createCookieOperations(cookieNames.fetypouser);

  const { get: getDocCheckRedirect, set: setDocCheckRedirect, remove: removeDocCheckRedirect } = createCookieOperations(cookieNames.docCheckRedirect);

  const { get: getRefCode, set: setRefCode, remove: removeRefCode } = createCookieOperations(cookieNames.refCode);

  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app,
    additionalProperties: {
      state: {
        getCartId,
        setCartId,
        removeCartId,

        getCustomerToken,
        setCustomerToken,
        removeCustomerToken,

        getStore,
        setStore,
        removeStore,

        getCurrency,
        setCurrency,
        removeCurrency,

        getLocale,
        setLocale,
        removeLocale,

        getCountry,
        setCountry,
        removeCountry,

        getMessage,
        setMessage,
        removeMessage,

        getFeTypoUserId,
        setFeTypoUserId,
        removeFeTypoUserId,

        getDocCheckRedirect,
        setDocCheckRedirect,
        removeDocCheckRedirect,

        getRefCode,
        setRefCode,
        removeRefCode,
      },
    },
  });

  plugin.integration.configure('magento', settings);
});
