export default `
query getProductAdditionalData($filter: ProductAttributeFilterInput) {
  products(filter: $filter) {
    items {
      sku
      uid
      id
      url_key

      swatch_image

      unit_type
      unit_value
      item_unit
      inhalt
      inhalt_label
    }
  }
}
`;
