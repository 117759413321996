import Vue from 'vue';
import { StripeElementPayment, StripePlugin } from '@vue-stripe/vue-stripe';
export default () => {
  // const options = {
  //   pk: process.env.VSF_STRIPE_API_KEY,
  //   testMode: false, // Boolean. To override the insecure host warning
  // };
  // Vue.use(StripePlugin, options);
  Vue.component('StripeElementPayment', StripeElementPayment);
};
