import type { Product } from '~/modules/catalog/product/types';

export default `
	query getProductDiscountQuery(
		$customerEmail:String,$productId:String!,$storeCode:String!
	) {
		tissoDiscount(customerEmail: $customerEmail, productId: $productId, storeCode: $storeCode) {
			customerId
			customerEmail
			customerGroup
			productId
			storeCode
			discount
		}
	}
`;
