import { Plugin } from '@nuxt/types';
import formatCurrency from '~/helpers/formatCurrency';

interface FormatCurrency {
  $fc(value: number | string, options?: Intl.NumberFormatOptions, locale?: string): string;
}

declare module 'vue/types/vue' {
  interface Vue extends FormatCurrency {}
}

declare module '@nuxt/types' {
  interface Context extends FormatCurrency {}
}

const plugin: Plugin = (context, inject) => {
  inject('fc', (value: number | string, options: Intl.NumberFormatOptions = {}, locale?: string): string => {
    // eslint-disable-next-line no-param-reassign
    locale = locale || context.i18n?.localeProperties?.iso.replace('_', '-');
    // eslint-disable-next-line no-param-reassign
    if (!options.currency) {
      options.currency = context.app.$vsf.$magento.config.state.getCurrency() || context.i18n?.localeProperties?.defaultCurrency;
    }
    return formatCurrency(value, locale, options);
  });
};

export default plugin;
