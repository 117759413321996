















import ProductMagentoCard from '~/components/ProductMagentoCard.vue';
import {
  defineComponent,
  ref,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import useRelatedProducts from '~/modules/catalog/product/composables/useRelatedProducts';
import { ExtendedProduct } from '~/customQueries/product/types/customTypes';

export default defineComponent({
  name: 'ProductRelatedProducts',
  components: {
    ProductMagentoCard,
  },

  props: {
    magentoSKU: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { search, loading } = useRelatedProducts();
    const products = ref([]);
    const { query } = useApi();
    const fetchError = ref(false);

    const currentProdData = {
      product: null,
      discount: null,
      options: null,
    } as ExtendedProduct;

    onMounted(async () => {
      const baseSearchQuery = {
        filter: {
          sku: {
            eq: props.magentoSKU,
          },
        },
      };
      products.value = await search(baseSearchQuery);
    });

    return {
      products,
      loading,
      search,
      currentProdData,
      fetchError,
    };
  },
});
