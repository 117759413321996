




































































































































































































































































import { useUiNotification, useUiState } from '~/composables';
import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import { useProdSubConfigState } from '~/composables/useProdSubConfigState';
import { useCart } from '~/modules/checkout/composables/useCart';

import productGetters from '~/modules/catalog/product/getters/productGetters';

import {
  SfModal,
  SfRadio,
  SfQuantitySelector,
  SfSelect,
  SfLoader,
  SfPrice,
} from '@storefront-ui/vue';
import { emit } from 'process';

import { useApi } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import type { ProductDetailsQuery } from '~/modules/GraphQL/types';
import getCustomProductOptionsGql from '~/customQueries/product/queries/getCustomProductOptions.gql';
import ProductDiscountGql from '~/customQueries/product/queries/getProductDiscounts.gql';
import getProductOptionsGql from '~/customQueries/product/queries/getProductOptions.gql';
import {
  ProductDiscountQuery,
  ExtendedProduct,
  ProductOptionsQuery,
} from '~/customQueries/product/types/customTypes';
import { CustomQuery } from '~/types/core';
import { useUser } from '~/modules/customer/composables/useUser';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import { getVAT, getVATtext } from '~/composables/utils/functions';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import ProductRelatedProducts from '~/components/ProductPage/ProductRelatedProducts.vue';
import { useMainNavStore } from '~/stores/mainNavStore';
import IframeElement from '~/components/IframeElement.vue';

export default defineComponent({
  name: 'ProductSubConfigModal',
  components: {
    IframeElement,
    SfModal,
    SfRadio,
    SfSelect,
    SfLoader,
    SfPrice,
    SfQuantitySelector,
    ProductRelatedProducts,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      closeProdSubConfigModal,
      isProdSubConfigModalOpen,
      productSku,
      isEnableCartUpdate,
      getCartItem,
      setProductSku,
      showRelatedProducts,
      setShowRelatedProducts,
      relatedSku,
    } = useProdSubConfigState();

    const { query } = useApi();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();
    const {
      cart,
      load: loadCart,
      addItem,
      error: cartError,
      loading: isCartLoading,
      canAddToCart,
      updateItemQty,
    } = useCart();
    const { selectedStore } = useMagentoConfiguration();
    const { toggleCartSidebar } = useUiState();
    const { app } = useContext();
    const mainNavStore = useMainNavStore();

    // Local reactive state
    const fetchError = ref(false);
    const currentCustomerGroupId = ref('');
    const oneTimeProdQty = ref<Number>(1);
    const subscriptionProdQty = ref<Number>(1);
    const subPeriod = ref('');
    const oneTimeSubVal = ref('oneTime');
    const onGoingSubVal = ref('onGoing');
    const subOption = ref(oneTimeSubVal.value);
    const subOptionUid = ref('');
    const subOptionId = ref(-1);
    const selectedSubOptId = ref('');
    const totalPrice = ref(0.0);
    const oneTimeAppliedDiscount = ref(0);
    const subAppliedDiscount = ref(0);
    const vatText = getVATtext();
    const subOptions = ref([]);
    const dataLoading = ref(false);
    const relatedProductSku = ref('');

    const currentProdData = {
      product: null,
      discount: null,
      options: null,
    } as ExtendedProduct;

    // Helper functions
    const isOneTimeSub = () => subOption.value === oneTimeSubVal.value;
    const isOnGoingSub = () => subOption.value === onGoingSubVal.value;
    const subConfigChange = (val) => (subOption.value = val);
    const periodChange = (val) => (selectedSubOptId.value = val);
    const closeSubConfigModal = (toggleCart) => {
      if (toggleCart) {
        toggleCartSidebar();
      }
      setShowRelatedProducts(false);
      emit('close');
    };
    const customTexts = computed(() => mainNavStore.customTexts);
    const nl2br = (str) => {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }
      var breakTag = '<br>';
      return (str + '').replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1' + breakTag + '$2',
      );
    };

    const fetchProductData = async () => {
      let sku = productSku.value;
      if (sku == '') {
        return;
      }

      try {
        dataLoading.value = true;

        const { data } = await query<ProductDetailsQuery>(
          getCustomProductOptionsGql,
          { sku: sku },
        );
        currentProdData.product = data.products?.items?.[0] as Product;

        const { data: data2 } = await query<ProductDiscountQuery>(
          ProductDiscountGql,
          {
            productId: sku,
            storeCode: selectedStore.value,
          },
        );

        if (data2?.tissoDiscount?.customerGroup) {
          currentCustomerGroupId.value = data2?.tissoDiscount?.customerGroup;
        }
        if (data2?.tissoDiscount?.discount) {
          let jsonData = JSON.parse(data2.tissoDiscount.discount);
          let key = Object.keys(jsonData)[0];
          currentProdData.discount = jsonData[key];
        }

        updateSubOptions();
      } catch (e) {
        console.error('Modal Exception: ', e);
        fetchError.value = true;
      } finally {
        dataLoading.value = false;
      }
    };

    const updateCartBtn = async () => {
      let cartItem = getCartItem();

      let subConfig = [];
      if (isOneTimeSub()) {
        // to remove item options
        subConfig.push({
          id: -1,
          value_string: '-1',
        });
      } else {
        if (isOnGoingSub()) {
          subConfig.push({
            // @ts-ignore
            id: parseInt(subOptionId.value),
            value_string: selectedSubOptId.value,
          });
        }
      }

      let qty = <number>currentProdQty.value;

      let params = {
        product: cartItem,
        quantity: qty,
        customOptions: subConfig,
      };

      await updateItemQty(params);

      emit('close');
    };

    const addToCartBtn = async () => {
      let subConfig = [];
      if (subOptionUid.value && isOnGoingSub()) {
        subConfig.push({
          uid: subOptionUid.value,
          value: selectedSubOptId.value,
        });
      }

      let qty = <number>currentProdQty.value;

      let price = getTotalPrice().onetime;
      if (isOnGoingSub()) {
        price = getTotalPrice().sub;
      }

      let pricePerUnit = price / qty;

      // @ts-ignore
      await addItem({
        product: currentProdData.product,
        quantity: qty,
        customOptions: subConfig,
      });

      try {
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_id: currentProdData?.product?.sku, //item ID or name is requred
                item_name: currentProdData?.product?.name, //item ID or name is requred
                price: Number(pricePerUnit.toFixed(2)), //price per unit
                quantity: qty,
                isOnGoingSub: isOnGoingSub(),
                crossSell: relatedSku.value,
              },
            ],
          },
        });
      } catch (e) {}

      if (cartError.value.addItem !== null) {
        sendNotification({
          id: Symbol('product_addtocart'),
          message: cartError.value.addItem.message,
          type: 'danger',
          icon: 'error',
          persist: true,
        });
      }

      loadCart();
      const productsInCart = computed(() => cartGetters.getItems(cart.value));

      dataLoading.value = true;

      if (productsInCart.value.length == 1) {
        if (productsInCart.value[0].quantity <= 1) {
          relatedProductSku.value = productsInCart.value[0].product.sku;
          // showRelatedProducts.value = true;
          setShowRelatedProducts(true);
          setProductSku('');
          dataLoading.value = false;
        } else {
          toggleCartSidebar();
          emit('close');
        }
      } else {
        toggleCartSidebar();
        emit('close');
      }
    };

    const getInhaltValue = (prod) => {
      let str = '';

      let pType = prod.inhalt_label;
      if (pType == 'powder') {
        str = prod.unit_value + ' ' + prod.unit_type + ' ' + app.i18n.t(pType);
      } else if (pType == 'other') {
        str = prod.unit_value + ' ' + prod.unit_type;
      } else if (
        pType == 'capsules' ||
        pType == 'drops' ||
        pType == 'tablets'
      ) {
        str =
          prod.unit_value +
          ' ' +
          prod.unit_type +
          ' = ' +
          prod.inhalt +
          ' ' +
          app.i18n.t(pType);
      } else if (pType == 'liquid') {
        str = prod.unit_value + ' ' + prod.unit_type;
      } else {
        str = prod.unit_value + ' ' + prod.unit_type;
      }

      return str;
    };

    const getQtyFromSku = (sku, splitStr) => {
      let qty = null;
      if (sku.includes(splitStr)) {
        let parts = sku.split(splitStr);
        if (parts.length >= 2) {
          qty = parseInt(parts[parts.length - 1]);
        }
      }
      return qty;
    };

    const getGroupSkuKeyword = () => {
      let optSuffix = '-N-'; // for non logged in users
      if (isAuthenticated.value) {
        // retailer
        if (currentCustomerGroupId.value == '3') {
          optSuffix = '-R-';
        }
        if (currentCustomerGroupId.value == '5') {
          optSuffix = '-K-';
        }
      }
      return optSuffix;
    };

    const getTotalPrice = () => {
      let productPrice = productGetters.getPrice(currentProdData.product);

      let finalPrice = 0.0;

      let oneTimeBasePrice = productPrice.final;
      let subBasePrice = productPrice.final;

      // @ts-ignore
      if (currentProdData.discount?.apply_discount_one_time != 0) {
        // @ts-ignore
        let allDiscounts = currentProdData.discount?.discounts;
        let ontimeDis = 0.0;

        if (allDiscounts) {
          for (let key in allDiscounts) {
            let dicount = allDiscounts[key];
            let disQty = parseInt(dicount.qty);
            if (oneTimeProdQty.value >= disQty) {
              oneTimeBasePrice = dicount.prices;
              ontimeDis = parseFloat(
                (
                  ((oneTimeBasePrice - productPrice.final) /
                    productPrice.final) *
                  100
                ).toFixed(2),
              );
            }
          }
        }

        oneTimeAppliedDiscount.value = Number(Math.ceil(ontimeDis));

        if (oneTimeBasePrice < 0) {
          oneTimeBasePrice = 0.0;
        }
      }

      let baseSingleItemPrice = 0.0;
      let totalSubOptPrice = 0.0;
      // @ts-ignore
      if (currentProdData.discount?.apply_discount_subscription != 0) {
        let baseDiscount = 0.0;
        let orgPriceDis = 0.0;

        subOptions.value.forEach((el) => {
          if (el.option_type_id == selectedSubOptId.value) {
            // totalSubOptPrice = (el.price >= 0) ? el.price : -1 * el.price;
            totalSubOptPrice = el.price + productPrice.final;
            baseSingleItemPrice = totalSubOptPrice;
            baseDiscount = productPrice.final - baseSingleItemPrice;

            // orgPriceDis = el.price;
            // baseDiscount =  el.price;

            orgPriceDis = parseFloat(
              (((-1 * baseDiscount) / productPrice.final) * 100).toFixed(2),
            );
          }
        });

        subAppliedDiscount.value = Number(Math.ceil(orgPriceDis));

        // let discountedPrice = subBasePrice * baseDiscount / 100;
        let discountedPrice = baseDiscount;
        subBasePrice -= discountedPrice;

        if (subBasePrice < 0) {
          subBasePrice = 0.0;
        }
      }

      // @ts-ignore
      let unitValue = currentProdData.product?.unit_value;

      return {
        onetime: oneTimeBasePrice * Number(oneTimeProdQty.value),
        onetime_inhalt: (oneTimeBasePrice / unitValue) * 1000,
        sub: totalSubOptPrice * Number(subscriptionProdQty.value),
        sub_inhalt: (subBasePrice / unitValue) * 1000,
        productPrice: productPrice.final,
      };
    };

    const getAddToCartTotal = computed(() => {
      if (isOneTimeSub()) {
        return getTotalPrice().onetime;
      } else if (isOnGoingSub()) {
        return getTotalPrice().sub;
      }
    });

    const currentProdQty = computed(() => {
      if (isOneTimeSub()) {
        return oneTimeProdQty.value;
      } else if (isOnGoingSub()) {
        return subscriptionProdQty.value;
      }
    });

    const updateSubOptions = () => {
      let cartItem = getCartItem();

      if (isSubOptAvailable()) {
        let subOpts = currentProdData.product?.options[0];

        let subId =
          isEnableCartUpdate &&
          cartItem &&
          cartItem.customizable_options?.length > 0
            ? cartItem.customizable_options[0].customizable_option_uid
            : subOpts.uid;
        subOptionUid.value = subId;

        subOptionId.value = subOpts.option_id;

        let optSuffix = getGroupSkuKeyword();

        // @ts-ignore
        let selectedSubOption = subOpts.dropDown.filter((el) =>
          el.sku.includes(optSuffix),
        );
        if (selectedSubOption.length > 0) {
          subOptions.value = [...selectedSubOption];
          if (
            isEnableCartUpdate &&
            cartItem &&
            cartItem.customizable_options?.length > 0
          ) {
            selectedSubOptId.value =
              cartItem.customizable_options[0].values[0].value;
          } else {
            selectedSubOption.forEach((el) => {
              let valStr = el.option_type_id.toString();
              let subOptQty = getQtyFromSku(el.sku, getGroupSkuKeyword());
              if (subOptQty == 1) {
                selectedSubOptId.value = valStr;
              }
            });
          }
        }
      }

      // if (cartItem && isEnableCartUpdate) {
      //   currentProdQty.value = cartItem.quantity;
      //   if (cartItem.customizable_options?.length > 0) {
      //     subOption.value = onGoingSubVal.value;
      //   }
      // }
    };

    const resetOptions = () => {
      oneTimeProdQty.value = 1;
      subscriptionProdQty.value = 1;
      subOption.value = oneTimeSubVal.value;
      oneTimeAppliedDiscount.value = 0.0;
      oneTimeAppliedDiscount.value = 0.0;
      fetchError.value = false;
    };

    const getDiscountNotice = () => {
      // @ts-ignore
      // prettier-ignore
      return currentProdData?.discount?.discounts && Object.keys(currentProdData.discount.discounts).length > 0 && subAppliedDiscount.value > 0 ? `${subAppliedDiscount.value}% ${app.i18n.t('quantity discount from')} ${Math.ceil(currentProdData.discount.discounts[Object.keys(currentProdData.discount.discounts)[0]].qty)} ${app.i18n.t('pieces')}` : ``;
    };

    const isSubOptAvailable = () => {
      // no sub option for TK group
      if (currentCustomerGroupId.value == '4') {
        return false;
      }

      return (
        currentProdData.product?.options !== null &&
        currentProdData.product?.options?.length > 0 &&
        // @ts-ignore
        currentProdData.product?.options[0].dropDown.length > 0
      );
    };

    const addToCartDescriptionText = computed(() => {
      return nl2br(customTexts.value['add-to-cart-description']);
    });

    const addToCartSubSavingsText = computed(() => {
      return nl2br(customTexts.value['add-to-cart-subscription-savings']);
    });

    const addToCartQuantityDiscountText = computed(() => {
      let text = '';
      let nextDiscounts = 0;

      let discountData = currentProdData?.discount;

      if (!discountData) return '';
      let discount = oneTimeAppliedDiscount.value;
      let basePrice = productGetters.getPrice(currentProdData.product).final;

      if (!discount) {
        // @ts-ignore
        let allDiscounts = discountData.discounts || null;

        if (allDiscounts) {
          let qty = currentProdQty.value;
          let minDiscPrice = null;
          for (let dk in allDiscounts) {
            if (qty <= parseInt(allDiscounts[dk].qty)) {
              if (minDiscPrice === null) {
                minDiscPrice = allDiscounts[dk].prices;
              } else {
                minDiscPrice =
                  minDiscPrice > allDiscounts[dk].prices
                    ? allDiscounts[dk].prices
                    : minDiscPrice;
              }
            }
          }

          if (minDiscPrice) {
            let dis =
              minDiscPrice < basePrice
                ? minDiscPrice - basePrice
                : basePrice - minDiscPrice;
            discount = (dis / basePrice) * 100;
          }
        }
      }

      const discountPrice = basePrice - (basePrice * -discount) / 100;

      text = customTexts.value['add-to-cart-quantity-discount'];

      if (text) {
        if (discount < 0) {
          text = text.replace(
            '###DISCOUNT###',
            app.$fc(discountPrice.toString()),
          );
        } else {
          text = '';
        }
      }

      return nl2br(text);
    });

    watch(
      () => props.visible,
      async (newVal, oldVal) => {
        if (newVal) {
          // displayed
          await fetchProductData();
        } else {
          // hidden
          await resetOptions();
        }
      },
    );

    watch(
      () => productSku.value,
      async (newVal, oldVal) => {
        if (newVal) {
          // displayed
          await fetchProductData();
        } else {
          // hidden
          await resetOptions();
        }
      },
    );

    return {
      isEnableCartUpdate,
      subOptions,
      oneTimeAppliedDiscount,
      subAppliedDiscount,
      getTotalPrice,
      getAddToCartTotal,
      isSubOptAvailable,
      dataLoading,
      isCartLoading,
      selectedSubOptId,
      currentProdQty,
      oneTimeProdQty,
      subscriptionProdQty,
      oneTimeSubVal,
      onGoingSubVal,
      subOption,
      subConfigChange,
      isOnGoingSub,
      isOneTimeSub,
      addToCartBtn,
      periodChange,
      updateCartBtn,
      isProdSubConfigModalOpen,
      productSku,
      fetchError,
      customTexts,
      // @ts-ignore
      getInhaltItemUnit: () => currentProdData.product?.item_unit || '',
      oneTimeText: computed(
        () => currentProdData.discount?.one_time_text || '',
      ),
      subscriptionText: computed(
        () => currentProdData.discount?.subscription_text || '',
      ),
      productData: computed(() => currentProdData || ''),
      addToCartDescriptionText,
      addToCartSubSavingsText,
      addToCartQuantityDiscountText,
      getVAT,
      vatText,
      relatedProductSku,
      showRelatedProducts,
      getProductDataFromT3: computed(() => {
        return mainNavStore.getProductDataFromT3(currentProdData.product);
      }),
      closeSubConfigModal,
      getDiscountNotice,
      getInhaltValue,
    };
  },
});
