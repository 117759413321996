








import {
  useRoute,
  defineComponent,
  onBeforeMount,
  onMounted,
  computed,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'EmptyLayout',

  components: {
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  head: {},
});
