import { useContext } from '@nuxtjs/composition-api';

export const getVAT = () => {
  const {
    app: { i18n },
  } = useContext();
  const locale = i18n.localeProperties.code;

  if (locale == 'de' || locale == 'de_ch') {
    return '/de/zahlung-lieferung/';
  } else if (locale == 'en' || locale == 'en_ch') {
    return '/en/payment-delivery/';
  }
};
export const replaceAll = (str: string) => {
  return str?.replace(/[^a-zA-Z0-9äöüÄÖÜß]/g, '-')?.toLowerCase() || '';
};

export const tabTranslator = (str: string) => {
  switch (str.toLowerCase()) {
    case 'übersicht':
    case 'overview':
      return 'overview';
    case 'verzehrempfehlung':
    case 'recommended intake':
      return 'recommended-intake';
    case 'zusammensetzung':
    case 'composition':
      return 'composition';
  }
};

export const getVATtext = () => {
  const {
    app: { i18n },
  } = useContext();
  const locale = i18n.localeProperties.code;

  if (locale === 'de_ch' || locale === 'en_ch') {
    return 'excl. VAT';
  } else {
    return 'incl. VAT';
  }
};

export const getMonthlysubs = (product: any) => {
  return product.toLowerCase() === 'monatliche lieferung' ? true : false;
};

export const getQuarterlysubs = (product: any) => {
  return product.toLowerCase() === 'quartalsweise lieferung' ? true : false;
};

export const getProductEndpoint = (locale: any) => {
  switch (locale) {
    case 'de':
      return 'produkte';
    case 'de_ch':
      return 'produkte';
    case 'en':
      return 'products';
    case 'en_ch':
      return 'products';
  }
};
