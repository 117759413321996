<template>
  <span
    class="d-block"
    v-if="
      showContent(
        product.productType,
        product.productAmount,
        product.productWeight,
        product.productScaleUnit,
      )
    "
  >
    <template v-if="showLabel">
      <template
        v-if="product.productType == 'Other' || product.productType == 'other'"
      >
        {{ $t('Volumen') }}:
      </template>
      <template v-else> {{ $t('Content') }}: </template>
    </template>
    {{
      showContent(
        product.productType,
        product.productAmount,
        product.productWeight,
        product.productScaleUnit,
      )
    }}
  </span>
</template>

<script>
export default {
  name: 'ProductShowContentsByType',

  props: {
    product: Object,
    showLabel: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    showContent(pType, pAmount, pWeight, pScaleUnit) {
      if (pType == 'Pulver' || pType == 'Flasche' || pType == 'powder') {
        return pWeight + ' ' + pScaleUnit + ' ' + this.$t(pType);
      } else if (pType == 'other') {
        return pWeight + ' ' + pScaleUnit;
      } else if (
        pType == 'Kapseln' ||
        pType == 'Tropfen' ||
        pType == 'Tabletten' ||
        pType == 'capsules' ||
        pType == 'drops' ||
        pType == 'tablets'
      ) {
        return (
          pWeight + ' ' + pScaleUnit + ' = ' + pAmount + ' ' + this.$t(pType)
        );
      } else if (pType == 'liquid') {
        return pWeight + ' ' + pScaleUnit;
      } else {
        // return pType + ' ' + this.product.uid // debug
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/styles/misc/vars';
</style>
