import { computed, reactive } from '@nuxtjs/composition-api';
import { ProdStateInterface, UseProdSubConfigStateInterface } from './useProdSubConfigState';
import { ExtendedProduct } from '~/customQueries/product/types/customTypes';
import type { SimpleCartItem } from '~/modules/GraphQL/types';

const state = reactive<ProdStateInterface>({
  isProdSubConfigModalOpen: false,
  productSku: '',
  loading: false,
  enableCartUpdate: false,
  data: null,
  cartItem: null,
  showRelatedProducts: false,
  relatedSku: '',
});

/**
 * Global store for managing Product Sub Config state.
 *
 * See the {@link UseProdSubConfigState} for a list of methods and values available in this composable.
 */
export function useProdSubConfigState(): UseProdSubConfigStateInterface {
  const closeProdSubConfigModal = () => {
    state.productSku = '';
    state.relatedSku = '';
    state.showRelatedProducts = false;
    state.isProdSubConfigModalOpen = false;
    state.enableCartUpdate = false;
  };
  const openProdSubConfigModal = () => {
    state.isProdSubConfigModalOpen = true;
  };

  const setProductSku = (sku: string) => {
    state.productSku = sku;
  };

  const getProductSku = () => {
    return state.productSku;
  };

  const setData = (data: ExtendedProduct) => {
    state.data = data;
  };

  const getData = () => {
    return state.data;
  };

  const setCartItem = (item: SimpleCartItem) => {
    state.cartItem = item;
    state.productSku = item.product.sku;
    state.enableCartUpdate = true;
  };

  const getCartItem = () => {
    return state.cartItem;
  };

  const setEnableCartUpdate = (val: boolean) => {
    state.enableCartUpdate = val;
  };

  const setRelatedSku = (sku: string) => {
    state.relatedSku = sku;
  };

  const setShowRelatedProducts = (val: boolean) => {
    state.showRelatedProducts = val;
  };

  return {
    isProdSubConfigModalOpen: computed(() => state.isProdSubConfigModalOpen),
    showRelatedProducts: computed(() => state.showRelatedProducts),
    isEnableCartUpdate: computed(() => state.enableCartUpdate),

    data: computed(() => state.data),

    loading: computed(() => state.loading),

    productSku: computed(() => state.productSku),

    setProductSku,
    getProductSku,

    setCartItem,
    getCartItem,

    setData,
    getData,

    closeProdSubConfigModal,
    openProdSubConfigModal,

    setEnableCartUpdate,
    setShowRelatedProducts,
    setRelatedSku,
    relatedSku: computed(() => state.relatedSku),
  };
}

export default useProdSubConfigState;
export * from './useProdSubConfigState';
