import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isRestrictedPage: false,
  isLoginModalPassResetForm: false,
  isLoginModalGuestCheckout: false,
  isOutOfStockModalOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
    if (state.isLoginModalOpen === false) {
      state.isLoginModalPassResetForm = false;
      state.isLoginModalGuestCheckout = false;
    }
  };

  const toggleOutOfStockModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isOutOfStockModalOpen = !state.isOutOfStockModalOpen;
    // if (state.isLoginModalOpen === false) {
    //   state.isLoginModalPassResetForm = false;
    //   state.isLoginModalGuestCheckout = false;
    // }
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleRestrictedPageStatus = () => {
    state.isRestrictedPage = !state.isRestrictedPage;
  };

  const toggleLoginModalWithPassResetForm = () => {
    state.isLoginModalPassResetForm = !state.isLoginModalPassResetForm;
    toggleLoginModal();
  };

  const toggleLoginModalGuestCheckout = (email: string) => {
    state.isLoginModalGuestCheckout = !state.isLoginModalGuestCheckout;
    toggleLoginModal();
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isOutOfStockModalOpen: computed(() => state.isOutOfStockModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isRestrictedPage: computed(() => state.isRestrictedPage),
    isLoginModalPassResetForm: computed(() => state.isLoginModalPassResetForm),
    isLoginModalGuestCheckout: computed(() => state.isLoginModalGuestCheckout),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleOutOfStockModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleRestrictedPageStatus,
    toggleLoginModalWithPassResetForm,
    toggleLoginModalGuestCheckout,
  };
}

export default useUiState;
export * from './useUiState';
