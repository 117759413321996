var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.showContent(
      _vm.product.productType,
      _vm.product.productAmount,
      _vm.product.productWeight,
      _vm.product.productScaleUnit
    )
  )?_c('span',{staticClass:"d-block"},[(_vm.showLabel)?[(_vm.product.productType == 'Other' || _vm.product.productType == 'other')?[_vm._v("\n      "+_vm._s(_vm.$t('Volumen'))+":\n    ")]:[_vm._v(" "+_vm._s(_vm.$t('Content'))+": ")]]:_vm._e(),_vm._v("\n  "+_vm._s(_vm.showContent(
      _vm.product.productType,
      _vm.product.productAmount,
      _vm.product.productWeight,
      _vm.product.productScaleUnit
    ))+"\n")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }