import { ref, readonly, computed, useContext, useRoute } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useUiNotification } from '~/composables/useUiNotification';
import type { Customer } from '~/modules/GraphQL/types';
import type { UseTypo3UserInterface, UseTypo3UserErrors, UseTypo3UserLoginParams, UseTypo3UserLogoutParams } from './useTypo3User';

import md5 from 'md5';
import axios from 'axios';

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseTypo3UserInterface} for a list of methods and values available in this composable.
 */
export function useTypo3User(): UseTypo3UserInterface {
  const route = useRoute();

  const { app } = useContext();

  const loading: Ref<boolean> = ref(false);
  const errorsFactory = (): UseTypo3UserErrors => ({
    login: null,
    logout: null,
  });
  const error: Ref = ref(errorsFactory());

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const logout = async (params: UseTypo3UserLogoutParams = {}) => {
    Logger.debug('[T3] UseTypo3UserFactory.logout');
    resetErrorValue();

    try {
      loading.value = true;

      const apiState = app.context.$vsf.$magento.config.state;

      let slug = `magento-login-sync/?logintype=logout`;
      let locale = route.value?.path?.split('/')?.length ? route.value?.path?.split('/')[1] : 'de';

      let jsonPath = '/typo3-api/' + locale + '/' + slug;

      const response = await axios.get(jsonPath);
      Logger.debug('[Typo3 Logout Result]:', { response });

      // remove local cookie
      apiState.removeFeTypoUserId();

      error.value.logout = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('UseTypo3User/logout', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async (params: UseTypo3UserLoginParams): Promise<void> => {
    Logger.debug('[T3] UseTypo3User.login', params);

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      let email = params.email;
      let hash = md5(email + 'bkc.QPD5ghx6wvr@vgy');

      let slug = `magento-login-sync/?logintype=login&typogento=1&username=${email}&hash=${hash}`;
      let locale = route.value?.path?.split('/')?.length ? route.value?.path?.split('/')[1] : 'de';

      // let jsonPath = baseURL + basePath + locale + "/" + slug;
      let jsonPath = '/typo3-api/' + locale + '/' + slug;

      const response = await axios.get(jsonPath);
      Logger.debug('[Typo3 Login Result]:', { response });

      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('UseTypo3User/login', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    login,
    logout,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useTypo3User;
export * from './useTypo3User';
