<template>
  <header class="pageHeader">
    <div class="pageHeader--top" v-bind:class="{ 'lvl-1-open': nextLevel }">
      <div class="container">
        <div class="row">
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="col-lg-6 col-xs-12 d-flex align-items-center"
              v-bind:class="{ 'd-none': mobileNav }"
            >
              <!-- HEADER LOGO COMPONENT -->
              <figure class="image--logo image d-lg-block d-none">
                <T3Link :toHome="true">
                  <template #content>
                    <svg
                      width="134"
                      class="image--logo-desktop"
                      height="32"
                      viewBox="0 0 134 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1874_4550)">
                        <path
                          d="M8.69024 28.514V7.33294H0V4.12909H21.1334V7.33294H12.1953V28.514H8.69024V28.514Z"
                          fill="#3E3E3F"
                        />
                        <path
                          d="M25.6851 28.514V4.12909H29.1901V28.514H25.6851Z"
                          fill="#3E3E3F"
                        />
                        <path
                          d="M34.6162 22.2432L38.0179 20.7097C38.8925 23.496 40.9239 25.8646 44.7801 25.8646C48.1474 25.8646 49.9653 24.2627 49.9653 21.9625C49.9653 19.943 48.5261 18.7244 45.5169 18.0946L42.2598 17.3621C38.438 16.5611 35.8488 14.7127 35.8488 10.571C35.8488 6.42927 39.2161 3.63617 44.4013 3.63617C49.5866 3.63617 52.5338 6.94271 53.2706 9.38668L49.9791 10.8859C49.4213 9.24976 47.9477 6.77841 44.0915 6.77841C41.0409 6.77841 39.3607 8.31188 39.3607 10.3999C39.3607 12.4878 40.5864 13.3641 43.2514 13.9529L46.6187 14.6854C50.7159 15.5548 53.6631 17.855 53.6631 21.6544C53.6631 25.9057 50.5093 29.0069 44.5873 29.0069C39.1197 29.0069 35.7937 26.0768 34.6369 22.25L34.6162 22.2432Z"
                          fill="#3E3E3F"
                        />
                        <path
                          d="M57.251 22.2432L60.6527 20.7097C61.5272 23.496 63.5586 25.8646 67.4148 25.8646C70.7753 25.8646 72.6001 24.2627 72.6001 21.9625C72.6001 19.943 71.1609 18.7244 68.1517 18.0946L64.8945 17.3621C61.0728 16.5611 58.4836 14.7127 58.4836 10.571C58.4836 6.42927 61.844 3.63617 67.0361 3.63617C72.2282 3.63617 75.1686 6.94271 75.9054 9.38668L72.6138 10.8859C72.0561 9.24976 70.5824 6.77841 66.7262 6.77841C63.6757 6.77841 61.9955 8.31188 61.9955 10.3999C61.9955 12.4878 63.2212 13.3641 65.8861 13.9529L69.2465 14.6854C73.3438 15.5548 76.291 17.855 76.291 21.6544C76.291 25.9057 73.1372 29.0069 67.2151 29.0069C61.7476 29.0069 58.4216 26.0768 57.2648 22.25L57.251 22.2432Z"
                          fill="#3E3E3F"
                        />
                        <path
                          d="M79.7134 16.3215C79.7134 8.7637 85.0019 3.64301 92.0464 3.64301C99.0908 3.64301 104.276 8.7637 104.276 16.3215C104.276 23.8793 99.0908 29 92.0464 29C85.0019 29 79.7134 23.9135 79.7134 16.3215V16.3215ZM100.564 16.3215C100.564 10.8859 97.3762 6.81263 92.0119 6.81263C86.6477 6.81263 83.4594 10.8859 83.4594 16.3215C83.4594 21.7571 86.6477 25.8304 92.0119 25.8304C97.3762 25.8304 100.564 21.7571 100.564 16.3215Z"
                          fill="#3E3E3F"
                        />
                        <path
                          d="M112.767 8.57492C112.491 8.527 112.188 8.527 111.933 8.42431C111.458 8.23263 111.203 7.86295 111.135 7.34267C111.128 7.26052 111.114 7.18521 111.1 7.10306C111.107 6.96615 111.128 6.82923 111.148 6.69916C111.217 6.24733 111.672 5.71336 112.202 5.69282C112.443 5.68597 112.691 5.6449 112.911 5.56959C113.455 5.38476 113.82 4.78917 113.772 4.22096C113.731 3.68014 113.297 3.13248 112.801 3.05033C112.161 2.94079 111.637 3.02979 111.307 3.69383C111.148 4.01559 111.135 4.4058 111.045 4.76863C110.942 5.19992 110.721 5.5559 110.274 5.69967C109.771 5.87081 109.303 5.81605 108.91 5.43952C108.559 5.09723 108.511 4.64541 108.483 4.20043C108.449 3.63222 107.871 2.95448 107.182 3.0024C107.017 3.02979 106.845 3.02979 106.693 3.08455C106.211 3.26939 105.956 3.63222 105.86 4.13881C105.736 4.78232 106.204 5.49429 106.858 5.60382C107.134 5.65174 107.437 5.65175 107.691 5.75443C108.167 5.94612 108.428 6.31579 108.49 6.83608C108.497 6.91823 108.511 6.99353 108.525 7.07568C108.525 7.2126 108.497 7.34951 108.477 7.47958C108.408 7.93826 107.953 8.46539 107.416 8.48592C107.175 8.49277 106.927 8.53384 106.707 8.60915C106.163 8.79399 105.798 9.38957 105.846 9.95778C105.887 10.4986 106.321 11.0463 106.817 11.1284C107.457 11.238 107.981 11.149 108.311 10.4849C108.47 10.1632 108.483 9.77294 108.573 9.41011C108.676 8.97882 108.897 8.62284 109.344 8.47908C109.847 8.30793 110.315 8.3627 110.708 8.73922C111.059 9.07467 111.107 9.53334 111.135 9.97832C111.169 10.5465 111.747 11.2243 112.436 11.1763C112.601 11.149 112.773 11.149 112.925 11.0942C113.407 10.9094 113.662 10.5465 113.758 10.0399C113.882 9.39642 113.414 8.68445 112.76 8.57492"
                          fill="#3E3E3F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1874_4550">
                          <rect
                            width="113.786"
                            height="27"
                            fill="white"
                            transform="translate(0 2)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </template>
                </T3Link>
              </figure>
              <figure class="image--logo image d-lg-none d-block">
                <T3Link :toHome="true">
                  <template #content>
                    <svg
                      width="26"
                      class="image--logo-mobile"
                      height="28"
                      viewBox="0 0 26 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.6727 19.0928C21.7619 18.9334 20.7885 18.9204 19.9528 18.5691C18.4004 17.9184 17.5522 16.6399 17.3456 14.8637C17.3143 14.5937 17.2799 14.3172 17.2361 14.0407C17.258 13.5787 17.3174 13.1135 17.3863 12.6548C17.6179 11.0966 19.0921 9.28454 20.8448 9.20972C21.6273 9.17719 22.438 9.05357 23.1735 8.78681C24.9481 8.14593 26.1375 6.11596 25.9904 4.16731C25.8495 2.32927 24.4442 0.452196 22.8073 0.16917C20.7009 -0.195184 18.9982 0.0878412 17.9121 2.38132C17.3894 3.48415 17.3394 4.82445 17.0545 6.05415C16.7103 7.53434 15.9904 8.73801 14.535 9.24876C12.898 9.82457 11.3644 9.6489 10.0624 8.35088C8.90117 7.19276 8.7572 5.641 8.66643 4.11526C8.55376 2.16661 6.65391 -0.14964 4.39726 0.00651207C3.86205 0.0976007 3.29554 0.0976007 2.80102 0.296044C1.22668 0.930411 0.391001 2.17963 0.0686226 3.90381C-0.344523 6.1062 1.18912 8.52981 3.32684 8.90717C4.23764 9.06658 5.2079 9.07959 6.04671 9.43093C7.59601 10.0816 8.44734 11.3601 8.65391 13.1363C8.68521 13.4096 8.72277 13.6828 8.76346 13.9593C8.74155 14.4213 8.68208 14.8865 8.61322 15.3452C8.38161 16.9035 6.90743 18.7155 5.15469 18.7903C4.37222 18.8228 3.56158 18.9464 2.82606 19.2132C1.05141 19.8508 -0.13795 21.8808 0.0122845 23.8294C0.15313 25.6675 1.55845 27.5446 3.19538 27.8276C5.3018 28.1919 7.00446 27.9089 8.09053 25.6154C8.61322 24.5126 8.6633 23.1723 8.94812 21.9426C9.29241 20.4624 10.0123 19.2587 11.4677 18.748C13.1015 18.1722 14.6383 18.3479 15.9403 19.6459C17.1015 20.804 17.2455 22.359 17.3331 23.8815C17.4458 25.8334 19.3456 28.1497 21.5991 27.9935C22.1344 27.9024 22.7009 27.9024 23.1954 27.704C24.7728 27.0696 25.6085 25.8236 25.9309 24.0962C26.3441 21.8938 24.8104 19.467 22.6727 19.0928Z"
                        fill="#3E3E3F"
                      />
                    </svg>
                  </template>
                </T3Link>
              </figure>
              <!-- HEADER LOGO COMPONENT -->
            </div>

            <div
              class="col-xs-12 d-none"
              v-bind:class="{
                'd-block-important': mobileNav,
                'w-100': mobileNav,
                'back-container': isOpen,
              }"
            >
              <div
                class="pageHeader--collapse-header"
                v-bind:class="{ 'd-none': nextLevel }"
              >
                <ul
                  class="blanklist language-switcher--wrapper d-block d-lg-none"
                  v-if="!isOpen"
                >
                  <LanguageSwitcher />
                </ul>

                <button v-else v-on:click="openDropDown('')" class="lang-nav">
                  <span class="prevLevel icon-arrow-left">{{
                    $t('Back')
                  }}</span>
                </button>
              </div>
            </div>

            <div
              class="col-xs-12 d-none"
              v-bind:class="{ 'd-block-important': nextLevel }"
            >
              <button
                class="prevLevel icon-arrow-left"
                v-on:click="nextLevelFunction()"
              >
                <span>{{ $t('Back') }}</span>
              </button>
            </div>

            <div
              class="col-lg-6 col-xs-12 d-flex align-items-center justify-content-end"
            >
              <div
                v-if="!mobileNav"
                class="d-flex align-items-center mx-6 gap-3 mobile-icons d-lg-none"
              >
                <SearchOverlay />
                <!--<span @click="goToSearch" class="icon-search"></span>-->
                <span @click="handleAccountClick" class="icon-user"></span>
                <span
                  @click="toggleCartSidebar"
                  class="icon-shopping-bag"
                ></span>
              </div>

              <button
                class="burger-nav d-block d-lg-none"
                v-on:click="toggleClass"
                v-bind:class="{
                  animate: mobileNav,
                }"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div class="pageHeader--top-controls d-none d-lg-flex">
                <CtaMenu class="pageHeader--top-controls--item blanklist">
                  <template #after>
                    <LanguageSwitcher />
                  </template>
                </CtaMenu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainNav
      :mobileNav="mobileNav"
      :nextLevel="nextLevel"
      :isOpen="isOpen"
      @openDropDown="openDropDown"
      @nextLevelFunction="nextLevelFunction"
    />
  </header>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';
import MainNav from './MainNav.vue';
import CtaMenu from './CtaMenu.vue';
import SearchOverlay from '~/components/SearchOverlay';
import T3Link from '~/components/General/T3Link.vue';

import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import {
  useRouter,
  useContext,
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useMainNavStore } from '~/stores/mainNavStore';

export default defineComponent({
  name: 'AppHeader',

  components: {
    LanguageSwitcher,
    MainNav,
    CtaMenu,
    T3Link,
    SearchOverlay,
  },

  data() {
    return {
      nextLevel: false,
      isOpen: '',
    };
  },

  head() {
    return {
      htmlAttrs: {
        class: this.mobileNav ? 'no-scroll' : '',
      },
    };
  },

  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleLoginModal, toggleMobileMenu, toggleCartSidebar } =
      useUiState();
    const { isAuthenticated } = useUser();
    const mainNavStore = useMainNavStore();
    const mobileNav = computed(() => mainNavStore.mobileNav);
    const categories = useCategoryStore();

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    const goToRegister = async () => {
      await router.push(
        app.localeRoute({ name: 'typo3', params: { slug: 'registrieren' } }),
      );
    };
    const goToSearch = async () => {
      await router.push(app.localeRoute({ name: 'search' }));
    };
    const loadCategoryMenu = async () => {
      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    const toggleClass = () => {
      mainNavStore.updateMobileNav(mobileNav.value);
    };

    return {
      toggleCartSidebar,
      loadCategoryMenu,
      toggleLoginModal,
      handleAccountClick,
      goToRegister,
      isAuthenticated,
      mobileNav,
      toggleClass,
      goToSearch,
    };
  },
  methods: {
    nextLevelFunction: function () {
      this.nextLevel = !this.nextLevel;
    },

    openDropDown: function (value) {
      this.isOpen = value;
    },

    getHomeLink: function () {
      return '/' + this.$i18n.localeProperties.code + '/';
    },
  },
});
</script>

<style lang="scss" scoped></style>
