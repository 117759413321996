<template>
  <ul class="cta-menu">
    <slot name="before" />
    <template v-for="(item, i) in data">
      <li class="hasSub" :key="item.link" v-if="i < data.length - 2">
        <T3Link :href="item.link" :class="getCtaClass(i, 'left')"
          ><template #content>{{ item.title }}</template></T3Link
        >
      </li>
    </template>
    <slot name="after" />
  </ul>
</template>

<script>
import T3Link from '~/components/General/T3Link.vue';
import { useMainNavStore } from '@/stores/mainNavStore';
import { useLanguageStore } from '@/stores/languageStore';
import { $gtm } from '../stores/mainNavStore';
import { useUser } from '~/modules/customer/composables/useUser';
import {
  watch,
  onBeforeMount,
  computed,
  ref,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';

import { useCart } from '~/modules/checkout/composables/useCart';
import { useUiState } from '~/composables';

export default {
  name: 'CtaMenu',
  props: {
    mobileNav: Boolean,
    nextLevel: Boolean,
    isOpen: String,
    nextLevelFunction: Function,
    openDropDown: Function,
  },
  components: {
    T3Link,
  },

  setup() {
    const mainNavStore = useMainNavStore();
    const { toggleCartSidebar, toggleLoginModal } = useUiState();
    const isLoggedIn = ref(false);
    const data = computed(() => mainNavStore.ctaMenu);
    const { app, localeRoute } = useContext();
    const router = useRouter();
    const { logout } = useUser();
    const { clear } = useCart();

    const { isAuthenticated } = useUser();

    onBeforeMount(() => {
      isLoggedIn.value = mainNavStore.isLoggedIn;
    });

    const logoutClick = async () => {
      await logout({});
      await clear({});
      await router.push(localeRoute({ name: 'home' }));
    };

    const handleLoginClick = async () => {
      if (isLoggedIn.value || isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        mainNavStore.showDocCheckLogin = false;
        toggleLoginModal();
      }

      try {
        // console.log("Data Layer push: ");
        // app.$gtm.push({ event: 'sign_up', 'method':'website', 'user_type': 'B2B', 'user_id':'xxxxx' })
      } catch (e) {}
    };

    return {
      toggleLoginModal,
      toggleCartSidebar,
      data,
      showChild: '',
      handleLoginClick,
      isLoggedIn,
      router,
      isAuthenticated,
      logoutClick,
    };
  },

  methods: {
    getCtaClass(i, left) {
      if (left && i === 1) {
        return 'btn btn-pill btn-icon';
      }
      if (i == 1) {
        return 'btn btn-secondary';
      }
      if (i == 2) {
        return 'btn btn-primary';
      }
    },

    getList(index, list) {
      if (index == 0 && list?.length) {
        let newArray = [];
        let newChildren = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].title == 'Divider') {
            newArray.push(JSON.parse(JSON.stringify(newChildren)));
            newChildren = [];
          } else {
            newChildren.push(list[i]);
          }
        }
        newArray.push(JSON.parse(JSON.stringify(newChildren)));
        return newArray;
      }
      return [];
    },

    spliceList(list) {
      let chunk = [];
      for (var i = 0, len = list?.length; i < len; i += 9) {
        chunk.push(list?.slice(i, i + 9));
      }
      return chunk;
    },

    getchild(list) {
      let tempArr = [];
      list.map((item) => {
        if (item?.children?.length) {
          tempArr = [
            ...list,
            ...item.children.map((d) => ({
              ...d,
              isChild: true,
              parentTitle: item.title,
            })),
          ];
        } else {
          tempArr = list;
        }
      });

      return tempArr;
    },

    showChildHandle(value) {
      this.showChild = value;
    },

    getMenuCardFirst(list) {
      if (list?.length) {
        return list.slice().splice(0, 1);
      } else {
        return [];
      }
    },

    getMenuCardSec(list) {
      if (list?.length) {
        return list.slice();
      } else {
        return [];
      }
    },

    getHtml(html) {
      if (html?.length) {
        return html[0].menu_bottom_cta;
      } else {
        return '';
      }
    },
    getIcon(icons) {
      if (icons?.length) {
        return icons[0].icon_class_name;
      } else {
        return '';
      }
    },
    getImage(list) {
      if (list?.length) {
        return list[0]?.publicUrl;
      } else {
        return '/img/dummy.png';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cta-menu {
  min-height: 2.9rem;
  font-weight: 600;
}
</style>
