<template>
  <div class="product-card" :id="`product` + product.uid" v-if="product.link">
    <T3Link :href="product.link" class="d-block d-md-flex h-100">
      <template #content>
        <div class="product-card-body">
          <header class="product-card-header mb-2 overflow-hidden bg-sand">
            <RatioImage
              :imgSrc="product.teaserImage"
              :elemClass="'ratio-1x1 m-0'"
            />
            <template v-if="!product.outOfStock">
              <pre>{{ product.magentoSku }}</pre>
              <button
                type="button"
                class="btn btn-primary"
                @click.stop.prevent="addProductToCart(product.magentoSku)"
              >
                {{ $t('Add to Cart') }}
              </button>
            </template>
          </header>
          <h3 class="text-preset-details" v-if="product.title">
            {{ product.title }}
          </h3>
          <ProductShowContentsByType
            class="body-3-regular mb-2"
            :product="product"
            :showLabel="true"
          />

          <template v-if="product.teaserText.length >= 149">
            <p
              class="body-2-regular"
              v-html="product.teaserText.substring(0, 149) + '...'"
              v-if="product.teaserText"
            ></p>
          </template>

          <template v-else>
            <p
              class="body-2-regular"
              v-html="product.teaserText"
              v-if="product.teaserText"
            ></p>
          </template>
        </div>
      </template>
    </T3Link>
    <div
      class="product-card-footer d-flex justify-content-between align-items-end"
      v-if="product.smallInfo || product.price || product.discountPrice"
    >
      <div class="info">
        <ProductWeightInfo :product="product" :tax="product.productTaxLabel" />
      </div>
      <div class="price text-end" style="max-width: max-content">
        <template v-if="!product.discountPrice && product.price">
          <span class="d-block text-preset-details">
            {{ $fc(product.price) }}
          </span>
        </template>
        <template v-else>
          <span
            v-if="product.price"
            class="d-block body-regular-3 text-decoration-line-through text-danger"
            >{{ $fc(product.price) }}</span
          >
          <span
            class="d-block text-preset-details"
            v-if="product.discountPrice"
          >
            {{ $fc(product.discountPrice) }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RatioImage from './RatioImage.vue';
import ProductWeightInfo from '@/components/ProductWeightInfo.vue';
import ProductShowContentsByType from '@/components/ProductPage/ProductShowContentsByType.vue';
import { useProdSubConfigState } from '~/composables/useProdSubConfigState';
import { useLanguageStore } from '../stores/languageStore';
import { computed } from '@nuxtjs/composition-api';
import T3Link from './General/T3Link.vue';
export default {
  name: 'ProductCard',
  components: {
    RatioImage,
    ProductWeightInfo,
    ProductShowContentsByType,
    T3Link,
  },
  props: {
    product: Object,
    related: String,
  },
  setup(props, context) {
    const store = useLanguageStore();

    const {
      openProdSubConfigModal,
      setProductSku,
      setShowRelatedProducts,
      setRelatedSku,
    } = useProdSubConfigState();

    const addProductToCart = function (sku) {
      setProductSku(sku);
      setShowRelatedProducts(false);
      setRelatedSku(props.related);
      openProdSubConfigModal();
      return false;
    };

    return {
      addProductToCart,
    };
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.headlessproducts_productslisting {
  .product-card {
    margin-bottom: 2rem;
  }
}

.product-card > a {
  text-decoration: none;
  color: $anthracite;
  @include transition($transition-base);
  .product-card-header {
    position: relative;
    .product-badge {
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: $anthracite;
    }
    .btn {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      width: 100%;
    }
    figure {
      @include transition($transition-base);

      img {
        transform: scale(0.7);
        object-fit: contain;
      }
    }
  }
  .product-card-footer {
    .info {
      p {
        font-size: 0.875rem;
        margin: 0 0 0.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .price {
      span {
        &.text-danger {
          font-size: 0.75rem;
        }
      }
    }
  }
  &:hover {
    color: $primary !important;
    .product-card-header {
      figure {
        @include transform(scale(1.05));
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .product-card > a {
    flex-wrap: wrap;

    > * {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .product-card-footer {
      margin-top: auto;
    }
  }
}
</style>
