export default `
query getExtraCustomerInfo {
  customer {
    gender
    phonenumber
    marketing_note

    addresses {
      firstname
      lastname
      telephone
      company
      street
      city
      postcode
      country_code
      default_billing
    }
  }
}
`;
